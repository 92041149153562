
export default defineNuxtRouteMiddleware(to => {
    if (process.server || process.client)
    {
        const tenantStore = useTenantStore();

        if(!tenantStore.getAuth)
        {
            return navigateTo('/');
        }


        if(!tenantStore.getTenantId || !tenantStore.getTenantUrl)
        {
            tenantStore.logout();
            return navigateTo('/');
        }

        if(tenantStore.getAuth)
        {
            tenantClientFetch('me',{
                method:'GET',
                onResponse({request,response,options})
                {
                    if(response.status === 401)
                    {
                        tenantStore.logout();
                        return navigateTo('/');
                    }

                    if(response.status === 200)
                    {
                        tenantStore.setUser(response._data);
                    }

                    if(response.status === 404)
                    {
                        tenantStore.logout();
                        return navigateTo('/');
                    }
                }
            })
        }
    }
})